.base {
  font-size: var(--step--small-medium);
  text-decoration: none;
  user-select: none;
  cursor: pointer;
  /*transition: all 200ms ease;*/
  width: fit-content;
  border-radius: 4px;
  transition:border  200ms ease,background-color 200ms ease, box-shadow 200ms ease;
  padding: 0.1em 2em 0.1em;
  border: 1px solid;

}

.base:active, .base:hover {
  background:var(--yellow-hover);
  border: var(--yellow-hover-stroke) solid 1px;
  box-shadow: 0 0 10px 0 var(--yellow-hover-stroke);
  color: var(--white);
  text-shadow: none;
}

.regular {
  color: white;
  background-color: var(--green);
  border-color: var(--green-stroke);
  box-shadow: 0 0 3px 0 var(--green-stroke);
  composes: base;
}
.transparent{
  color: var(--green);
  box-shadow: 0 0 3px 0 var(--green-stroke),
              inset 0 0 3px 0 var(--green-stroke);
  text-shadow: 0 0 3px var(--green-stroke);

  background: transparent;
  composes: base;
}

.background{
    margin: 0;
    font-family: monospace, sans-serif;
    min-height: 100vh;
    display: grid;
    place-content: center;
    background: var(--dark);
    position: relative;

}
.background .container div{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-right: 5vw;
}

.container h1{
    font-size: var(--step-big);
    display: inline;
    margin: 0;
    padding: 0;
    width: max-content;
    position: relative;
}

.container {
    --typewriterSpeed: 1.3s;
    --iterations:2;
}
.subtitle {
    margin-top: 1rem;
    color: var(--white);
    font-size: var(--step--small-medium);
    font-weight: 400;
    line-height: calc(var(--step--small-medium) - 6px);


    width: fit-content;
    margin-bottom: 0;
}

.line{
    width: 98.5%;
    margin:var(--step--small) 0;
    opacity: 0.5;
}

.container h1::before,
.container h1::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: -2px;
}

/*img*/
.circle {
    height: calc(var(--step-big)*8.3);
    width: calc(var(--step-big)*8.3);
    background-color: var(--yellow);
    border-radius: 50%;
    position: absolute;
    right: calc(var(--step-big)*2);
    bottom: calc(0px - var(--step-big)*2);
    /*padding-left:calc(var(--step-big)*6) ;*/
    opacity: 0;
    transform: translateY(48px);
    animation: fadeInUp 2s ease 2s forwards;
}
.circle img{
    position: absolute;
    bottom: calc(var(--step-big)*1.9);
    width: calc(var(--step-big)*8);
    height: auto;
    left:calc(var(--step-big)*0.5) ;
    opacity: 0;
    transform: translateY(48px);
    animation: fadeInUp 2s ease 0s forwards;
}
/*else*/
.container>div:nth-child(1){
    position: relative;
    right: calc(var(--step-big)*3);
}
@media (max-width:766px ) {
    .container{
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
    }
    .container>div:nth-child(1){
        position: relative;
        left: 20px;
        bottom: 100px;
    }
}
/*----------------animation---------------------*/
.container h1:nth-child(1)::before {
    background: var(--dark);
    animation: typewriter calc(var(--typewriterSpeed))
    steps(16) forwards;
}
.container h1:nth-child(1)::after {
    width: 0.125em;
    background: var(--white);
    animation: typewriter var(--typewriterSpeed)
    steps(16) forwards,
    blink 750ms forwards;
    animation-iteration-count: 1, calc(var(--iterations));
}
.container h1:nth-child(2)::before {
    background: var(--dark);
    animation: typewriter var(--typewriterSpeed) steps(16) calc(var(--typewriterSpeed))  forwards;
}
.container h1:nth-child(2)::after {
    width: 0.125em;
    animation:
            typewriter var(--typewriterSpeed) steps(16) forwards,
            blink 750ms forwards;
    animation-iteration-count: 1,var(--iterations);
    animation-delay:calc(var(--typewriterSpeed));
}
.container h1:nth-child(3)::before {
    background: var(--dark);
    animation: typewriter var(--typewriterSpeed) steps(11) calc(var(--typewriterSpeed)*2)  forwards;
}
.container h1:nth-child(3)::after {
    width: 0.125em;
    animation: typewriter var(--typewriterSpeed) steps(11)   forwards,
    blink 750ms steps(16) infinite;
    animation-iteration-count: 1,var(--iterations);
    animation-delay:calc(var(--typewriterSpeed)*2);
}
.container h1:nth-child(4)::before {
    background: var(--dark);
    animation: typewriter calc(var(--typewriterSpeed)/2) steps(6) calc(var(--typewriterSpeed)*3) forwards;
}
.container h1:nth-child(4)::after {
    width: 0.125em;
    animation:
            typewriter calc(var(--typewriterSpeed)/2) steps(6)   forwards,
            blink 750ms steps(16) infinite;
    animation-iteration-count: 1,infinite;
    animation-delay:calc(var(--typewriterSpeed)*3);
}

.container>div>div{
    width: fit-content;
}
.container>div>div>p{
    opacity: 0;
    transform: translateY(48px);
    animation: fadeInUp 2s ease calc(var(--typewriterSpeed)*4 - 0.5s ) forwards;
}
.container>div>div>hr{
    opacity: 0;
    transform: translateY(48px);
    animation: fadeInUp 2s ease calc(var(--typewriterSpeed)*4 - 0.3s ) forwards;
}
.container>div>div>a{
    opacity: 0;
    transform: translateY(48px);
    animation: fadeInUp 2s ease calc(var(--typewriterSpeed)*4 ) forwards;
}

@keyframes typewriter {
    to {
        left: 100%;
    }
}
@keyframes blink {
    0%{
        background: transparent;
    }
    1%{
        background: var(--white);
    }

    100% {
        background: transparent;
    }
}
@keyframes fadeInUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

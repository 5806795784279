.Footer{
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    padding:var(--step--medium) var(--step-big)  0;
}
.Footer div:nth-child(1){
    margin-right: var(--step-big);
    margin-bottom: var(--step-big);
}
.Footer div a{
    line-height: 25px;
}

.Copyright{
    padding-left: var(--step-big);
    opacity: 0.5;
    margin-bottom: 0;
    padding-bottom: 10px;
}
footer{
    background-color: var(--dark);
}
.background{
    min-height: 100vh;
    background-color: var(--darker);
    height: fit-content;
    z-index: 1;
    position: relative;
}
.container{
    /*background-color: black;*/
    margin: 0 auto;
    max-width: 1500px;
    display: grid;
    row-gap: 5vh;
    padding: 5vw;
}


/*each element*/

.container p{
    opacity: .6;
    /*width: 70%;*/
}
.container img{
    float: right;
    margin: 6px 6px 6px var(--step-big);
    height: calc(3 * var(--step-big))
}
.makingOfDesign img, .makingOfSite img{
    height: calc(4 * var(--step-big))
}
.container div:after{
    content: '';
    mask: url("../../images/IndexImages/folderPart.svg");
    background-color: var(--dark);
    position: absolute;
    right: 0;
    top:calc(-1vw);

    width:100px;
    height:13px;
}
@media (min-width:1200px ) {
    .container{
        /*background-color: black;*/
        display: grid;
        column-gap: 20px;
        row-gap: 55px;
        grid-template-columns: repeat(2,1fr);
        padding: 100px;
    }
    .blog{
        grid-column: span 2;
    }

    .container div:after{
        content: '';
        top:calc(-0.4vw - 16px);
        width:15vw;
        height:2vw;

    }

}

.container>div{
    background-color: var(--dark);
    position: relative;
    border-radius: 5px 0 5px 5px ;
    padding: var(--step--medium);
}
.container h3{
    display: inline-block;
}

/*element*/
.uiComponents h3,.calculator h3{
    color: var(--red);
    text-shadow: 0 0 10px var(--red);
}
.makingOfDesign h3,.makingOfSite h3{
    color: var(--yellow);
    text-shadow: 0 0 10px var(--yellow);
}
.blog h3{
    color: var(--green);
    text-shadow: 0 0 10px var(--green);
}

.makingOfSite img{
    margin:0 clamp(-20px,-1vw,-5px) 0 0;
}


/*blog*/
.folders{
    display: flex;
    justify-content: space-between;
    padding:var(--step--medium) var(--step-big);

}
.smallFolder{
    background-color: var(--darker);
    min-height: 20vw;
    width: 40%;
    position: relative;
    padding: var(--step--small);
}
.smallFolder:after{
    z-index: 1;
    background-color: var(--darker) !important;
    width: 15vw!important;
    height:2vw !important;
}
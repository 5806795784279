/*fixed variant*/
.tv_filter{
    background: repeating-linear-gradient(
            0deg,
            transparent 1px,
            transparent 2px,
            black 2px,
            black 3px
    );
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 100;
    opacity: 0.1;
    pointer-events: none;
}
/*not fixed*/
/*.tv_filter{*/
/*    background: repeating-linear-gradient(*/
/*            0deg,*/
/*            transparent 1px,*/
/*            transparent 2px,*/
/*            black 2px,*/
/*            black 3px*/
/*    );*/
/*    position: absolute;*/
/*    z-index: 100;*/
/*    opacity: 0.1;*/
/*    pointer-events: none;*/
/*    top: 0;*/
/*    bottom: 0;*/
/*    left: 0;*/
/*    right: 0;*/
/*}*/
.project{
    position: relative;
    margin-bottom: 15vh;
}
.project p{
    display: inline;
}
.projectHeader{
    margin-bottom: 20px;
}
.project img{
    /*border-top: 2px solid rgba(0, 81, 255, 0.14);*/
    /*border-left: 2px solid rgba(69, 96, 180, 0.05);*/
    position: relative;
    display: block;
    width: 100%;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.53);
    border-radius: var(--boder-radius);
    transition: .3s;
    z-index: 101;
}
.project img:hover {
    box-shadow: 0 0 70px rgba(0, 0, 0, 0.55);
}
.title{
    color: var(--green-blue);
    margin-top: 15rem;
}

@media (min-width: 1000px) {
    .project h2,.project h3,.project p,.project a{
        position: relative;
        right: 13vw;
        padding-bottom: 10px;
    }
    .project img{
        margin-bottom: -5vh;
    }
    .project img:nth-child(2n){
        position: relative;
        right: -13vw;
    }
    .project img:nth-child(2n+1){
        position: relative;
        right: 13vw;
    }
}



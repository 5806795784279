.regular{
    padding: 0 2rem 0;
    margin:0 auto;
    max-width:700px ;
}
.head{
    padding: 30vh 2rem 0;
    margin:0 auto 10vmin;
    max-width:700px ;
}
.topWrapper{
    background-color: var(--dark);
    width:100%;
    height: 30vh;
    margin-bottom: 12px;

}
.topWrapper h3{
    display: block;
    padding-top: calc(30vh - 50px);
}

.background {


    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: column;
}
.background div{
    height: 80%;
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
}
.base{
    text-transform: uppercase;
    text-decoration: none;
    transition: 0.5s;
    font-size: 20px;
    padding: 10px 40px 11px;
    font-family: 'Calibri', sans-serif;
    font-weight: lighter;
    margin: 10px;
}
.buttonA{
    background-color: #596AFF;
    box-shadow: 0 10px 20px #596AFF20;
    color: #EEE;
    border-radius: 2px;
    composes: base;
}
.buttonA:hover{
    box-shadow: 0 10px 30px #596AFF55;
    background-color: #7785ff;
    transform: translateY(2px);
}
.buttonB{
    background-color: #59f1ff;
    box-shadow: 0 10px 20px rgba(89, 255, 249, 0.13);
    color: #000000;
    border-radius: 2px;
    composes: base;
}
.buttonB:hover{
    box-shadow: 0 10px 30px rgba(89, 249, 255, 0.33);
    background-color: #77fff4;
    transform: translateY(2px);
}
.buttonC{
    background-color: #2c5d56;
    border-radius: 8px;
    border: 1px solid black;
    composes: base;
    color: black;
    box-shadow: 0 1px 5px #00000033;
}
.buttonC:hover{
    box-shadow: 0 3px 10px #00000033;
    background-color: rebeccapurple;
}
.buttonD{
    box-shadow: 0 10px 20px rgba(222, 83, 136, 0.13);
    background-color: #e36161;
    color: black;
    composes: base;
    width: 68px;
    border-radius: 10px;
    text-align: center;
}
.buttonD:hover{
    letter-spacing: 5px;
    width: 100px;
}

.wrapDiagram{
    position: relative;
    /*min-width: 300px;*/
}
.wrapDiagram *{
    /*min-width: 300px;*/
}
.wrapDiagram svg:nth-child(1){
    z-index: 2;
    position: relative;
    /*max-width: 310px;*/
}
.wrapDiagram svg:nth-child(2),.wrapDiagram svg:nth-child(3){
    position: absolute;
    left: 0;
    filter: drop-shadow(0 0 3px green);

}
.notButton{
    background-color: var(--dark);
    border: none;
    transition: background-color 0.3s;
    padding: 0.6em 2em 0.5em;
    border-radius: var(--boder-radius);
    cursor: pointer;
    margin-bottom: 2rem;
}
.notButton:hover{
    background-color: var(--grey);
    transform: scale(1.05);
}

@media (min-width: 600px){
    .wrapDiagram{
        margin-left: 1rem;
        min-width: 300px;
    }
    .wrapper{
        display: flex;
    }

}
.background{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.background button{
    color:black;
    cursor: pointer;
}
.on{

}
.off{}
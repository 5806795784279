.wrapper{
    max-width: 900px;
    margin:0 auto;
    padding: 100px 2rem 0;
}
.background{
    position: relative;
    width: 100%;
    z-index: 3;
    background-color: var(--darker);
}
.links{
}
.links>a{
    display: block;
    text-decoration: none;
    margin-bottom: 4rem;
}
.links img{
    height: fit-content;
    width: 100%;
    background-color: var(--dark);
    border-radius: var(--boder-radius);
    margin-bottom: 10px;
}
.links p,.links a{
    font-size: 16px;
}
.textArea{

}
.links>a h3{
    font-size:22px ;
    color: var(--white);
    transition: color 0.2s;
}

.links>a:hover h3{
    color:var(--green-blue);
}
@media (min-width: 700px) {
    .links>a{
        display: flex;
        background-color: var(--dark);
        padding: 1rem;
        border-radius: var(--boder-radius);
        transition: border-radius .4s , box-shadow .4s ;
        box-shadow: 0 0 10px black;
    }
    .links>a:hover{
        border-radius: calc(var(--boder-radius) + 10px);
        box-shadow: 0 0 30px black;
    }
    .links img{
        background-color: transparent;
    }
}
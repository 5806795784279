
.swicher{
    width: 20px;
    height: 20px;
    background-color: black;
}
ul,li{
    padding: 0;
}
h1,h2,h3,h4,h5,input{
    padding: 0;
    margin: 0;
}

/*header*/
header{
    z-index: 99;
    align-items: center;
    width: 100%;
    position: fixed;
    display: flex;
    height: 64px;
}
header a{
    font-family: 'Calibri', sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
    user-select: none;
}
header .headerlist a{
    opacity: 0.8;
    color: var(--white);
    transition: opacity,color 0.2s;
}
header .headerlist a:hover{
    opacity: 1;
    color: var(--red-hover);
}
.logo{
    margin-top: 0px;
    margin-left: 30px;
    margin-right: calc(50% - 400px);

    font-family: 'Calibri', sans-serif;
    font-weight: lighter;
    font-size: min(48px,3vw);
    color:var(--white);
    text-decoration: none;
    transition: color 0.5s;
}
.logo:hover{
    color: var(--red-hover);
}
.headerlist {
    display: flex;
    list-style-type: none;
}
.headerlist li{
    margin-right: 3vw;
}
header .buttons{
    margin-left: auto;
    margin-right: 20px;
    display: flex;

}
header .buttons a{
    cursor: pointer;
    padding: 13px 18px 13px;
    border-radius: 5px;
}
.singin{
    color: var(--dark);
    background-color: var(--white);
    border: var(--white) solid 1px;
    margin-right: 13px;
    transition: background-color 0.4s;
}
.singin:hover{
    color: var(--dark);
    background-color:var(--red-hover);
}
.login{
    color: var(--white);
    border: var(--white) solid 1px;
    margin-right: 19px;
    transition: background-color 0.4s ;
}
.login:hover{
    background-color: var(--red-hover);
    border-color: var(--white);
    color: var(--dark);
}

.menuToggle {

    z-index: 11;
    display: none;
    position: fixed;
    top: 25px;
    left: 25px;
    -webkit-user-select: none;
    user-select: none;

}

.menuToggle a {
    text-decoration: none;
    color: var(--white);
    font-size: var(--step--small-medium);
    transition: color 0.3s ease;
}

.menuToggle a:hover
{
    color:var(--red-hover);
}


.menuToggle input
{
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;

    cursor: pointer;

    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */

    -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
.menuToggle span
{
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;

    background: var(--white);
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0;

    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
    background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
    opacity 0.55s ease;
}

.menuToggle span:first-child
{
    transform-origin: 0% 0%;
}

.menuToggle span:nth-last-child(2)
{
    transform-origin: 0% 100%;
}

/*
 * Transform all the slices of hamburger
 * into a crossmark.
 */
.menuToggle input:checked ~ span
{
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: var(--white);
}

/*
 * But let's hide the middle one.
 */
.menuToggle input:checked ~ span:nth-last-child(3)
{
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
.menuToggle input:checked ~ span:nth-last-child(2)
{
    transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
.menu
{
    position: absolute;
    width: 100vw;
    margin: -100px 0 0 -50px;
    padding: 50px;
    padding-top: 125px;

    backdrop-filter: blur(10px);
    background-color: rgba(50, 50, 50, 0.23);
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */

    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);

}
.menu ul{
    border:5px solid white;
    border-radius: 3px;
}
.menu li
{
    padding: 10px 0;
    font-size: 22px;
}

/*
 * And let's slide it in from the left
 */
.menuToggle input:checked ~ ul
{
    transform: none;
}
@media (max-width: 1000px) {
    .menuToggle{
        display: block;

    }
    header{
        display: none;
    }
}
input[type=checkbox] {
    /*hidden*/
    opacity: 0;
    position: relative;
    cursor: pointer;
    width: 50px;
    height: 0px;
}

input[type=checkbox]:checked:before {

    content: "";
    display: block;
    position: absolute;
    width: 34px;
    height: 34px;
    border: 4px solid #e87b0d;
    border-radius: 20px;
    /*background-color: #ffa300;*/
    transition: all 0.2s linear;
}


input[type=checkbox]:before {
    content: "";
    display: block;
    position: absolute;
    width: 34px;
    height: 34px;
    border: 4px var(--white) solid;
    border-radius: 3px;

    background-color: var(--dark);
    transition: all 0.2s linear;
}


@font-face{
  font-family: 'font556b91135ce16c853a03919796616dcc';
  src:url('https://nomail.com.ua/files/eot/556b91135ce16c853a03919796616dcc.eot?#iefix') format('embedded-opentype'),
  url('https://nomail.com.ua/files/woff/556b91135ce16c853a03919796616dcc.woff') format('woff'),
  url('https://nomail.com.ua/files/woff2/556b91135ce16c853a03919796616dcc.woff2') format('woff2');
}
@font-face{ font-family: 'ElementaCyr-Bold';  src: local('ElementaCyr-Bold'), url('https://nomail.com.ua/files/woff/556b91135ce16c853a03919796616dcc.woff') format('woff'); }
@font-face{
  font-family: 'font2127aa6d0680b144a3bd8be9debeba09';
  src:    url('https://nomail.com.ua/files/eot/2127aa6d0680b144a3bd8be9debeba09.eot?#iefix') format('embedded-opentype'),
  url('https://nomail.com.ua/files/woff/2127aa6d0680b144a3bd8be9debeba09.woff') format('woff'),
  url('https://nomail.com.ua/files/woff2/2127aa6d0680b144a3bd8be9debeba09.woff2') format('woff2');
}
@font-face { font-family: 'HelveticaNeueCyr-Light';  src: local('HelveticaNeueCyr-Light'), url('https://nomail.com.ua/files/woff/2127aa6d0680b144a3bd8be9debeba09.woff') format('woff'); }
:root{
  --scroll:0;
  --white: #e8e8e8;
  --red: #FF3F56;
  --red-hover: #ff586b;
  --green:#00B548;
  --green-stroke: #00f661;
  --yellow-hover:#E4E910;
  --yellow-hover-stroke: #faff30;
  --yellow:#C2C61F;
  --green-blue:#08D9D6;
  --dark: #1E293B;
  /*--dark: #252A34;*/
  --darker:#0F172A ;
  --grey: #475569;

  --step--small: clamp(18px, calc(6px + 0.9vw), 19px);
  --step--medium: clamp(30px, calc(8px + 1.83vw), 35px);
  --step--small-medium: clamp(25px, calc(8px + 1.83vw), 35px);
  --step-big: clamp(28px, calc(6px + 3.59vw), 94px);
  --boder-radius: 7px;
  font-size: clamp(12px, calc(2px + 2vw), 16px);
}
html{
  scroll-behavior: smooth;
}
h1,h2,h3,h4,h5,h6{
  color: var(--white);
}
body {

  /*transition: 0.2s;*/
  background: var(--darker);
  padding: 0;
  margin: 0;
  font-family: 'Calibri', sans-serif;
  font-weight: lighter;
  box-sizing: border-box;
}

h1,h2{
  font-family: 'Roboto Mono', monospace;
  font-weight: 700;
  font-size: var(--step-big);
  letter-spacing: 0.05em;
  color: var(--white);
  margin: 0;
  padding:-30px 0;
  text-transform: uppercase;
}
h3,h4{
  display: block;
  margin-bottom: 16px!important;
  font-family:'monospace',sans-serif;
  font-size: var(--step--medium);
  color: var(--white);
}
h4{
  margin-top:4rem!important; ;
}
h5{
  font-family:'ElementaCyr-Bold';

  font-weight: lighter;
  font-size: var(--step--small-medium);
}
@media (max-width: 700px) {
  h1,h2,h3,h4,h5,h6{
    font-weight: 300;
  }
}
p,a,button,em,code,li{
  font-size: var(--step--small);
  font-family:'HelveticaNeueCyr-Light',sans-serif;
  color: var(--white);
}
p{
  margin:0 0 1rem;
  opacity: 0.9;
}
code{
  font-family:'ElementaCyr-Bold',sans-serif;
}
p,code,li{
  line-height: 1.55;
  letter-spacing: 1px;
}
em{
  font-style: italic;
  font-family: Arial,sans-serif;
  opacity: 0.8;
}
li{
  margin-left: 20px;
}
/*ul{*/
/*  margin-bottom: 40px;*/
/*}*/
.link{
  position: relative;
  text-decoration: underline;
  text-underline-offset: var(--text-underline-offset,0.0em);
  opacity: 0.7;
  transition: opacity 400ms,color 400ms,text-shadow 400ms;
}
.link:hover{

  --text-underline-offset:0.05em;
  opacity: 1;
}
.redText{
  color: var(--red);
  display: inline;
  font-size: inherit;
  font-family: inherit;
}
.white{
  --white: #343434;
  --red: #006b72;
  --red-hover: #248684;
  --dark: #C4C4C4;
  --darker: #6d7482;
  --grey: #848d92;
}

.glichRedBlue{
  text-shadow:
          -2px 1px 0 rgba(255, 0, 0, 0.25),
          2px -1px 0 rgba(0, 0, 255, 0.25);
}
@media (orientation: landscape){
  ::-webkit-scrollbar {
    width: 12px;
    background-color: var(--dark);
}}
@media (orientation: landscape){
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--grey);
  border: 2px solid var(--grey);
}}
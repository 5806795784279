.diagramAndTable{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    /*margin-top: -150px;*/
}

.RightCircle,.LeftCircle,.BothCircles,.WithoutCircles{
    position: absolute;
    filter: drop-shadow(0 0 3px green);
    left: 0;
}
 .skeleton{
    position: relative;
    z-index: 2;
}
.diagram{
    margin-top: 30px;
    position: relative;
    /*height: min(50vw,222px);*/
    /*max-width: 393px;*/

    /*width: 393px;*/
    margin-bottom: 20px;
    display: block;
}
.diagramWrapper{
    margin-top: 42px;
    /*max-width: 393px;*/
}
@media (min-width: 700px) {
    .diagramWrapper{
        margin-top: 42px;
        max-width: 393px;
    }

}
.fadeIn{
    transform: translateY(0);
    opacity: 1;
    transition: 0.5s transform, opacity 0.5s;
}
.fadeOut{
    transform: translateY(10px);
    opacity: 0;
    transition: 0.5s transform, 0.5s opacity;
}
.pickOne{
    opacity: 0.7;
}
.logicOperationButtons{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.logicOperationButtons >*{
    text-align: center;
    display: inline-block;
    width: 90px;
    padding: 15px 10px;
    margin: 5px;
    background-color: var(--dark);
    border-radius: var(--boder-radius);
    user-select: none;
    border: none;
    transition: 0.2s background-color;
    cursor: pointer;

}
.logicOperationButtons >*:hover {
    background-color: var(--grey);
    transform: scale(1.05);
}


.table{

    display:block;
}
.table table{
    margin: 30px auto 0;
}
.table table tr:nth-child(2n){
    background-color: var(--dark);
}
.table table{
    border-spacing: 0;
}
.table table tr td{
    padding: 0.2rem 2rem;
}
.table table h3{
    margin: 0!important;
}
/*.table p {*/
/*    margin: 0;*/
/*    padding: 5px 0 ;*/
/*}*/
.tableWrapper h3{
    text-align: center;
    max-width: 250px;
    margin: 0 auto;
}
.tableWrapper{
    margin: 0 auto;
}

.explainWrapper{
   height: 100px;
    margin: 40px 0;
}
.explainTitle{
    animation: FadeIn 1s forwards;
}
@keyframes FadeIn {
    from{
        opacity: 0;
    }
    to{opacity: 1}
}